
<template>
  <canvas ref="snowCanvas" :width="canvasWidth" :height="canvasHeight"></canvas>
</template>
<script>
export default {
  props:{
	  snowflakeUrl: {
	    type: String,
	    default: "",
	  },
  },
  data() {
    return {
      canvasWidth: window.innerWidth,
      canvasHeight: window.innerHeight,
      snowflakes: [],
      snowflakeCount: 20,
      snowflakeSizeMax: 10,
      snowflakeSpeedMax: 2,
	  uelImseg:new Image(),
    };
  },
  mounted() {
    this.initSnowflakes();
    window.addEventListener('resize', this.handleResize);
    // this.animateSnowflakes();
	let imgLodiNG=this.uelImseg;
	imgLodiNG.src=this.snowflakeUrl;
	imgLodiNG.onload=()=>{
		this.animateSnowflakes(imgLodiNG);
	}
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    initSnowflakes() {
      for (let i = 0; i < this.snowflakeCount; i++) {
        this.snowflakes.push(this.createSnowflake());
      }
    },
    createSnowflake() {
      return {
        x: Math.random() * this.canvasWidth,
        y: Math.random() * -this.canvasHeight,
        size: Math.random() * this.snowflakeSizeMax + 1,
        speed: Math.random() * this.snowflakeSpeedMax + 0.5,
        angle: Math.random() * Math.PI * 2,
      };
    },
    drawSnowflakes() {
      const canvas = this.$refs.snowCanvas;
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);

      for (let snowflake of this.snowflakes) {
        ctx.save();
        ctx.translate(snowflake.x, snowflake.y);
        ctx.rotate(snowflake.angle);
        ctx.beginPath();
        // ctx.arc(0, 0, snowflake.size, 0, Math.PI * 2);
		ctx.drawImage(this.uelImseg,0,0,Math.PI*8,Math.PI*8);
        ctx.fillStyle = 'white';
        ctx.fill();
        ctx.restore();
      }
    },
    updateSnowflakes() {
      for (let snowflake of this.snowflakes) {
        snowflake.y += snowflake.speed;
        snowflake.angle += 0.02;

        if (snowflake.y > this.canvasHeight) {
          snowflake.y = Math.random() * -this.canvasHeight;
          snowflake.x = Math.random() * this.canvasWidth;
          snowflake.size = Math.random() * this.snowflakeSizeMax + 1;
          snowflake.speed = Math.random() * this.snowflakeSpeedMax + 0.5;
        }
      }
    },
    animateSnowflakes() {
      this.updateSnowflakes();
      this.drawSnowflakes();
      requestAnimationFrame(this.animateSnowflakes);
    },
    handleResize() {
      this.canvasWidth = window.innerWidth;
      this.canvasHeight = window.innerHeight;
    },
  },
};
</script>

<style scoped>
canvas {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
   pointer-events: none
}

</style>

<!-- <template>
  <canvas ref="canvas" class="canvas"></canvas>
</template>
<script>
	function random(min, max) {
	    return Math.random() * (max - min) + min;
	}
class Snowflake {
  constructor(x, y, speed, radius,image) {
    this.x = x;
    this.y = y;
    this.speed = speed;
    this.radius = radius;
	this.image=image;
	 this.angle = Math.random() * Math.PI * 2;
	   this.size = random([20, 50], [20, 50]);
	this.angleSpeed = random(-0.05, 0.05);
  }
  update() {
    this.y += this.speed;
	this.angle += this.angleSpeed;
    if (this.y > window.innerHeight) {
      this.y = -this.radius;
    }
	// console.log(this.y)
  }
  draw(ctx) {
    ctx.beginPath();
	 ctx.save();
	 ctx.translate(this.x, this.y);
	 ctx.rotate(this.angle);
	 ctx.drawImage(this.image,0,0,10 * Math.PI,10 * Math.PI);
	 // 绘制一个简单的雪花形状（例如，使用五角星代表）
	 ctx.beginPath();
	 // console.log(snowflakeAngle)
	 // for (let i = 0; i < 5; i++) {
	 //   ctx.lineTo(10 * Math.cos((i * 2 * Math.PI) / 5), 10 * Math.sin((i * 2 * Math.PI) / 5));
	 // }
	 // ctx.closePath();
	 // ctx.drawImage(this.image,this.x, this.y,10 * Math.PI,10 * Math.PI);
	 ctx.fill();
	 
	 ctx.restore();
  }
}
export default {
  data() {
    return {
      snowflakes: [],
	  snowflakeUrl:'https://front-o1.jingdd.xyz/202501/db3e468d-6a59-47fd-ab77-c74550751175.png',
	  uelImseg:new Image(),
	  randomLength:0,
    };
  },
  mounted() {
    this.canvas = this.$refs.canvas;
    this.ctx = this.canvas.getContext('2d');
    this.canvas.width = window.innerWidth;
    this.canvas.height = window.innerHeight;
    window.addEventListener('resize', () => {
      this.canvas.width = window.innerWidth;
      this.canvas.height = window.innerHeight;
    });
	let imgLodiNG=this.uelImseg;
	imgLodiNG.src=this.snowflakeUrl;
	imgLodiNG.onload=()=>{
		this.animate(imgLodiNG);
	}
    // this.animate();
  },
  methods: {
    animate() {
      this.ctx.clearRect(0, 0, window.innerWidth, window.innerHeight);
	  let random=Math.random();
      if ( random< 0.05 && this.randomLength<=20) {
		this.randomLength=this.randomLength+1;
        const x = Math.random() * window.innerWidth;
        const y = -10;
        const speed = Math.random() * 2 + 1;
        const radius = Math.random() * 2 + 1;
        this.snowflakes.push(new Snowflake(x, y, speed, radius,this.uelImseg));
      }
      this.snowflakes.forEach((snowflake) => {
        snowflake.update();
        snowflake.draw(this.ctx);
      });
	  requestAnimationFrame(this.animate);
    },
  },
};
</script>
<style>
.canvas {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
   pointer-events: none
}


</style> -->

<!-- <template>
  <div>
    <canvas ref="snowCanvas" width="500" height="500"></canvas>
  </div>
</template>
 
<script>
export default {
  name: 'SnowAnimation',
  data() {
    return {
      snowflakes: [],
	  snowflakeUrl:require('../../assets/icons/images/xuehua.png'),
      snowInterval: null
    };
  },
  methods: {
    initSnow() {
      const canvas = this.$refs.snowCanvas;
      const ctx = canvas.getContext('2d');
      this.snowInterval = setInterval(() => {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        this.snowflakes.forEach(snowflake => {
          snowflake.y += snowflake.speed;
          snowflake.x += snowflake.direction;
          if (snowflake.y > canvas.height) {
            snowflake.y = 0;
          }
          ctx.drawImage(snowflake.image, snowflake.x, snowflake.y, snowflake.size, snowflake.size);
        });
      }, 30);
    },
    createSnowflake() {
      const snowflake = {
        x: Math.random() * this.$refs.snowCanvas.width,
        y: 0,
        size: 5,
        speed: Math.random() * 1 + 1,
        direction: Math.random() > 0.5 ? -1 : 1,
        image: new Image()
      };
      snowflake.image.src = this.snowflakeUrl; // 雪花图片的路径
      this.snowflakes.push(snowflake);
    }
  },
  mounted() {
    for (let i = 0; i < 200; i++) {
      this.createSnowflake();
    }
    this.initSnow();
  },
  beforeDestroy() {
    if (this.snowInterval) {
      clearInterval(this.snowInterval);
    }
  }
};
</script>
 
<style>
canvas {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
   pointer-events: none
}


</style> -->