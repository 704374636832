<template>
  <div>
	  <van-search
	    v-bind="$attrs"
	    v-on="$listeners"
	    v-model="modelValue"
		@input="onInput"
		@clear="onClear"
		@focus.stop="isFus"
		@blur.stop="isFun"
		:left-icon='leftIcon'
		:class="{
			'input-active':isFocus
		}"
	  >
	    <template v-for="(_, slot) of $slots"  v-slot:[slot]="scope">
	      <slot :name="slot" :clearable="clearable" v-bind="scope"></slot>
	    </template>
	  </van-search>
  </div>
</template>
 
<script>
import { ref, watch, defineComponent } from 'vue';
import { Search } from 'vant';
 //console.log(Search.props,'http://localhost:8080/#/?id=1&code=pg&category=slots')
export default defineComponent({
  name: 'InheritSearch',
  components: {
    VanSearch: Search,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
	formKey:{
      type: String,
      default: '',
    },
	...Search.props
  },
  data() {
  	return{
		isFocus:false,
	}
  },
  methods:{
	isFun(e) {
		this.isFocus = false;
		this.$emit('blur',e,this.formKey)
	},
	isFus(e){
		this.isFocus = true;
		this.$emit('focus',e,this.formKey)
	}
  },
  setup(props, { emit }) {
    const currentValue = ref(props.modelValue);
	const formKey = ref(props.formKey);
    const onInput = (value) => {
      currentValue.value = value;
      emit('update:modelValue', value);
	  emit('onInput', value,formKey.value);
    };
 
    const onClear = (event) => {
      currentValue.value = '';
      emit('update:modelValue', '');
      emit('clear', event);
    };
 
    watch(() => props.modelValue, (value) => {
      currentValue.value = value;
    });
 
    return {
      currentValue,
      onInput,
      onClear,
    };
  },
});
</script>