<template>
	<div class="ant-input-box">
		<div class="input-box flexbox">
			<div class="jiznshi" v-if="disabled"></div>
		<!-- 	{{codeValue.length >= 5}}
			{{inputFocus}}_is-active -->
			<div class="code-item"
				:class="codeValue.length == 0 && inputFocus ? 'code-item-active' : codeValue&&codeValue.length >= 1 && !isShow?'display':''">
				<i></i>{{codeValue&&codeValue.length >= 1 && !isShow?'':codeValue[0]}}
			</div>
			<div class="code-item"
				:class="codeValue.length == 1 && inputFocus ? 'code-item-active' : codeValue&&codeValue.length >= 2 && !isShow?'display':''">
				<i></i>{{codeValue&&codeValue.length >= 2 && !isShow?'':codeValue[1]}}
			</div>
			<div class="code-item"
				:class="codeValue.length == 2 && inputFocus ? 'code-item-active' : codeValue&&codeValue.length >= 3 && !isShow?'display':''">
				<i></i>{{codeValue&&codeValue.length >= 3 && !isShow?'':codeValue[2]}}
			</div>
			<div class="code-item"
				:class="codeValue.length == 3 && inputFocus ? 'code-item-active' : codeValue&&codeValue.length >= 4 && !isShow?'display':''">
				<i></i>{{codeValue&&codeValue.length >= 4 && !isShow?'':codeValue[3]}}
			</div>
			<div class="code-item"
				:class="codeValue.length == 4 && inputFocus ? 'code-item-active' : codeValue&&codeValue.length >= 5 && !isShow?'display':''">
				<i></i>{{codeValue&&codeValue.length >= 5 && !isShow?'':codeValue[4]}}
			</div>
			<div class="code-item"
				 :class="{
					 'code-item-active':codeValue.length >= 5 && inputFocus && codeValue.length!=6,
					 display_a:inputFocus&&codeValue&&codeValue.length == 6,
					 display:codeValue&&codeValue.length == 6 && !isShow,
					 isShow:isShow,
				 }"
				>
				<!-- :class="codeValue.length >= 5 && inputFocus && codeValue.length!=6?'code-item-active' :inputFocus&&codeValue&&codeValue.length == 6?'display_a':codeValue&&codeValue.length == 6 && !isShow?'display':''" -->
				<i></i>{{codeValue&&codeValue.length >= 6 && !isShow?'':codeValue[5]}}
			</div>
			<!-- isShow -->
			<input type="number" class="input-code" maxlength="6" v-model="codeValue"  @blur="codeInputBlur"
				@focus="codeInputFocus" @input="codeInputChange">
		</div>
	</div>

</template>

<script>
	export default {
		props:{
			isShow:{
				type:Boolean,
				required:false,
			},
			disabled:{
				type:Boolean,
				required:false,
			}
		},
		data() {
			return {
				inputFocus: false,
				codeValue:'',
			};
		},
		methods: {
			// 验证码输入框
			codeInputChange(e) {
				if (this.codeValue.length>6) {
					this.codeValue=this.codeValue.substr(0,6)
				}
				this.$emit('change',this.codeValue)
			},
			// 验证码输入框失去焦点
			codeInputBlur() {
				this.inputFocus = false;
			},
			// 验证码输入框获取到焦点
			codeInputFocus() {
				this.inputFocus = true;
			},
		},
	}
</script>

<style scoped>
	.input-box {
		position: relative;
		border: thin solid var(--cms-divider-color);
		background-color:var(--cms-background-color-primary) ;
		border-radius: .14rem;
		overflow: hidden;
		width: 100%;
	}

	.ant-input-box {
		display: flex;
		align-items: center;
		position: relative;
		width: 100%;
	}
	.jiznshi{
		position: absolute;
		left: 0;
		top: 0;
		z-index: 999;
		width: 100%;
		height: 100%;
	}

	.input-code {
		position: absolute;
		background-color: initial;
		color: transparent !important;
		caret-color: transparent;
		/* color: rgba(red, green, blue, 0); */
	}

	.code-item {
		height: 1rem;
		text-align: center;
		color: var(--cms-text-color-primary);
		font-size: .3rem;
		flex: 1;
		line-height: 1rem;
		/* border: 1px solid #f0f0f0; */
	}

	.code-item:not(:last-child) {
		border-right: .01rem solid var(--cms-divider-color);
	}

	.input-box .code-item-active:nth-child(1) {
		border-bottom-left-radius: .14rem;
		border-top-left-radius: .14rem;
	}

	.input-box .code-item:nth-child(6) {
		border-bottom-right-radius: .14rem;
		border-top-right-radius: .14rem;
	}
	
	.display_a,.code-item-active {
		display: flex;
		align-items: center;
		justify-content: center;
		border: .01rem solid var(--cms-primary-color) !important;
		box-sizing: border-box;
	}
	.display_a.isShow i{
		display: none;
	}
	.display {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.display_a i,.display i {
		display: inline-block;
		height: .26rem;
		width: .26rem;
		background: var(--cms-text-color-primary);
		border-radius: 50%;
	}

	.code-item-active i {
		display: inline-block;
		height: .32rem;
		width: .025rem;
		animation: HNwOeA9BdOXQ8cLhf58t 1s infinite;
		background: var(--cms-text-color-primary);
	}

	@keyframes HNwOeA9BdOXQ8cLhf58t {
		0% {
			opacity: 0
		}

		50% {
			opacity: 1
		}

		to {
			opacity: 0
		}
	}

	.flexbox {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.input-box {
		.input-code {
			width: 100%;
			height: 1rem;
			background-color: transparent;
			border: none;
			color: transparent;
		}
	}
</style>
