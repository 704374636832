const ICON_NAV =[
	{name:'popular',svg:'icon_dtfl_rm_0--svgSprite:all',textName:"_Popular"},
	{name:'slots',svg:'icon_dtfl_dz_0--svgSprite:all',textName:"_Slots"},
	{name:'piscator',svg:'icon_dtfl_by_0--svgSprite:all',textName:"_Pescaria"},
	{name:'',svg:'icon_dtfl_sw_0--svgSprite:all',textName:"_Demo"},
	{name:'recent',svg:'icon_dtfl_zj_0--svgSprite:all',textName:"_Recente"},
	{name:'favorite',svg:'icon_dtfl_sc_0--svgSprite:all',textName:"_Favoritos"},
]

const ICON_NAV3Dian6 =[
	{name:'popular',svg:require('./../icons/3Dian6/66078f7c-b2da-4232-9b72-06f236b18f68.png'),textName:"_Popular"},
	{name:'slots',svg:require('./../icons/3Dian6/d52e7525-ac0e-40b2-bd01-3601c207e5bd.png'),textName:"_Slots"},
	{name:'piscator',svg:require('./../icons/3Dian6/b2e65139-1eef-466c-9b52-18e084f749e3.png'),textName:"_Pescaria"},
	{name:'demo',svg:require('./../icons/3Dian6/ecfbda17-6394-4515-90d5-984b27a2b44b.png'),textName:"_Demo"},
	{name:'recent',svg:require('./../icons/3Dian6/ff9fec8c-e207-4a7f-9d3a-96b7d98baba2.png'),textName:"_Recente"},
	{name:'favorite',svg:require('./../icons/3Dian6/ba74ec6c-6542-4236-9996-b98ce74e1c86.png'),textName:"_Favoritos"},
]

const ICON_NAV3Dian5 =[
	{name:'popular',svg:'MQU81kA6-icon_dtfl_rm_0:all',textName:"_Popular"},
	{name:'slots',svg:'kCWNzARO-icon_dtfl_dz_0:all',textName:"_Slots"},
	{name:'piscator',svg:'icon_dtfl_by_0--svgSprite:all',textName:"_Pescaria"},
	{name:'recent',svg:'hBkf9vyL-icon_dtfl_zj_0:all',textName:"_Recente"},
	{name:'favorite',svg:'rNuA70NJ-icon_dtfl_sc_0:all',textName:"_Favoritos"},
]
// icon_dtfl_rm_1.avif
// 			icon_dtfl_dz_1.avif
const ICON_NAV3Dian5Active =[
    require('./../icons/3Dian5/icon_dtfl_rm_1.png'),
    require('./../icons/3Dian5/icon_dtfl_dz_1.png'),
    require('./../icons/active/icon_dtfl_by_1.webp'),
    require('./../icons/active/icon_dtfl_zj_1.webp'),
    require('./../icons/active/icon_dtfl_sc_1.webp')
]

// 	{name:'_Slots',svg:'icon_dtfl_dz_0--svgSprite:all'},
// 	{name:'_Popular',svg:'icon_dtfl_by_0--svgSprite:all'},
// 	{name:'_Demo',svg:'icon_dtfl_sw_0--svgSprite:all'},
// 	{name:'_Recente',svg:'icon_dtfl_zj_0--svgSprite:all'},
// 	{name:'_Favoritos',svg:'icon_dtfl_sc_0--svgSprite:all'},
const ICON_NAVActive =[
    require('./../icons/active/icon_dtfl_rm_1.webp'),
    require('./../icons/active/icon_dtfl_dz_1.webp'),
    require('./../icons/active/icon_dtfl_by_1.webp'),
    require('./../icons/active/icon_dtfl_qkl_1.webp'),
    require('./../icons/active/icon_dtfl_zj_1.webp'),
    require('./../icons/active/icon_dtfl_sc_1.webp')
]

const ICON_search =[
    require('./../icons/active/icon_dtfl_2ss_1.webp'),
    require('./../icons/active/icon_dtfl_2rm_1.webp'),
    require('./../icons/active/icon_dtfl_2zj_1.webp'),
    require('./../icons/active/icon_dtfl_2sc_1.webp'),
]


export {
    ICON_NAV,
    ICON_NAVActive,
	ICON_search,
	ICON_NAV3Dian5,
	ICON_NAV3Dian5Active,
	ICON_NAV3Dian6
};