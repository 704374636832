var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"ant-layout-header -wsiy pCKI81C6T6W2e4ZAfsHW uJNjizvqNTc61kuO2wZO",style:({
	height:`${_vm.$store.state.xiazApp?.9+.7:.9}rem`,
	transition: '.3s',
	
})},[_c('div',{staticClass:"ant-layout-header-conter",staticStyle:{"background-color":"var(--cms-top-background-color)"}},[(_vm.$store.state.xiazApp)?_c('div',{staticClass:"v7Gu3VSoEuypS8B9EgaJ",staticStyle:{"display":"flex"}},[_c('div',{staticClass:"aG5W8rsptmZ9RO66S3cI",staticStyle:{"background-color":"var(--b-app-rgba)"}},[_c('div',{staticClass:"pxU3k4yaAHPfT3wK4R8v",staticStyle:{"display":"flex"},on:{"click":function($event){$event.stopPropagation();return _vm.qingCHuApp.apply(null, arguments)}}},[_c('svg-icon',{staticClass:"svg",staticStyle:{"color":"rgb(var(--theme-ant-primary-color-19))","width":".2rem","height":".2rem"},attrs:{"icon-class":"comm_icon_x--svgSprite:all"}})],1),_c('div',{staticClass:"DAEXSUBjuzUY1EoIAeEn LubvNYW6hJ8aFPwvFVVB",staticStyle:{"display":"flex"}},[_c('img',{staticClass:"goDRiiBsuEuXD3W1NphN GgAalyCT_nMrUn3ge8Q8 use-skeleton",staticStyle:{"height":"0.51rem","z-index":"1"},attrs:{"src":_vm.config.home_download_image,"alt":"."}})]),_c('div',{staticClass:"DvuNDsU5DEFvvcIAXWAe",staticStyle:{"display":"flex"},on:{"click":function($event){$event.stopPropagation();return _vm.$store.commit('publicFun',['AppDownload',true])}}},[_vm._m(0)])])]):_vm._e(),_c('van-nav-bar',{staticClass:"_Hp2NEpB_AxXucPPcXBD yejv0HlPp2rPD2V4Tg4O",style:({
			'background-image':`url(${_vm.config.navigate_bg_image})`,
			'background-size': 'initial',
			
		})},[_c('div',{staticClass:"XmxJUtbUHHdaQg3o2iDA",attrs:{"slot":"left"},slot:"left"},[_c('div',{staticClass:"ant-layout bkh5uQUT1mtuXLoAMzMG",on:{"click":function($event){$event.stopPropagation();return _vm.show_Popup()}}},[_c('svg-icon',{attrs:{"className":`${_vm.$store.state.showPopup?'fs20':'fs201'}`,"icon-class":"btn_zcl_arrow--svgSprite:all"}})],1),_c('div',{staticClass:"k2aKMnWti6qDRzhUpO8W"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.config.home_logo),expression:"config.home_logo"}],staticClass:"goDRiiBsuEuXD3W1NphN II_5ZM4k4hTlrmJknbDi customLogo",attrs:{"src":_vm.config.home_logo,"loading":"loaded"}})])]),_c('div',{staticClass:"geSGAKCHnajLSE515jEg",attrs:{"slot":"right"},slot:"right"},[(!_vm.vuex_token_head)?_c('div',{staticClass:"crcd82r1PVsQivOcCftk"},[_c('van-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){_vm.$store.commit('isRecord'),_vm.$store.commit('publicFun',['isRecordType',1])}}},[_vm._v(_vm._s(_vm.$t('Login._Login')))]),_c('van-button',{attrs:{"type":"default","size":"mini"},on:{"click":function($event){_vm.$store.commit('isRecord'),_vm.$store.commit('publicFun',['isRecordType',0])}}},[_vm._v(" "+_vm._s(_vm.$t('Login._Registro'))+" ")])],1):_c('div',{staticClass:"GgxC_2tF_TQt1DVfif5a"},[_c('section',{staticClass:"iODDzDZbB06hkkF6ltjq"},[_c('div',{staticClass:"vy918omw_N7rBkJ235XX",staticStyle:{"--row-align":"center","--mode":"flex","--column-align":"center"}},[_c('div',{staticClass:"lop_top",staticStyle:{"background-position":"0px 0px","background-size":"100% 100%"},style:({
									backgroundImage:`url(${_vm.img_hb_frame})`
								})},[_c('i',{staticClass:"yj5rHjAv0U7RlHMzWr7F img skeleton-box",staticStyle:{"display":"inline-block","position":"relative","width":"0.3rem","height":"0.3rem","background-position":"-7.15714rem -2.79643rem","background-size":"7.77857rem 7.59107rem"},style:({
										backgroundImage:`url(${_vm._coloImg})`
									})})]),_c('section',{staticClass:"_top_currency-count",staticStyle:{"font-size":"0.18rem"}},[_c('div',{staticClass:"countTextBox",on:{"click":_vm.animate}},[_c('span',{staticClass:"SSAbrhtT3U690CrzLUd5 count-to"},[_c('span',{staticClass:"notranslate"},[_vm._v(_vm._s(_vm.moneyInfo.balance_total_str))])]),_c('svg-icon',{attrs:{"icon-class":"comm_icon_sx--svgSprite:all"}})],1)])])]),_c('section',{staticClass:"aLXMjsO8hJ5Mj1gtyJTg top-selectDownArrow"},[_c('van-button',{staticClass:"van-but_ton",attrs:{"type":"primary"},on:{"click":function($event){return _vm.$store.commit('isDeposit',true)}}},[_c('span',[_vm._v(_vm._s(_vm.$t('Login._Depósito'))+" ")])]),_c('i',{staticClass:"anticon ant-dropdown-trigger",class:{
							'ant-dropdown-open':_vm.modelCon
						},on:{"click":function($event){_vm.modelCon=!_vm.modelCon}}},[_c('svg-icon',{staticStyle:{"width":".15rem","height":".15rem"},attrs:{"icon-class":"comm_icon_sort--svgSprite:all"}})],1),_c('div',{staticClass:"ant-dropdown ant-dropdown-placement-bottomRight",staticStyle:{"left":"34px","top":".65rem","width":"70%","position":"absolute"},style:({
								display:`${_vm.modelCon?'':'none'}`,
							})},[_c('ul',{staticClass:"ant-dropdown-menu ant-dropdown-menu-vertical ant-dropdown-menu-root ant-dropdown-menu-light ant-dropdown-content",attrs:{"role":"menu","tabindex":"0"}},[_c('li',{staticClass:"ant-dropdown-menu-item",attrs:{"role":"menuitem"},on:{"click":function($event){return _vm.routerFun('withdraw');}}},[_vm._v(" "+_vm._s(_vm.$t('Login._Saque')))]),_c('li',{staticClass:"ant-dropdown-menu-item",attrs:{"role":"menuitem"},on:{"click":function($event){return _vm.$router.push({name:'yuebao'})}}},[_vm._v(" "+_vm._s(_vm.$t('Login._Juros'))+" ")])])])],1)])])])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"ant-btn ant-btn-default",staticStyle:{"background-color":"rgb(var(--theme-ant-primary-color-19))","color":"var(--theme-primary-font-color)","border":"none"},attrs:{"type":"button"}},[_c('span',[_vm._v("Baixar Agora")])])
}]

export { render, staticRenderFns }