<template>
	<div class="">
		<HomeSanDianLiu v-if="config.ui_version==3.6"></HomeSanDianLiu>
		<HomeConter v-if="config.ui_version==3.0"></HomeConter>
		<four v-if="config.ui_version==3.5"></four>
	</div>
</template>
<script>
	import HomeSanDianLiu from "@/components/home/HomeSanDianLiu.vue"
	import HomeConter from "@/components/home/HomeConter.vue"
	import four from "@/components/home/four.vue"
	import { mapState } from 'vuex';
	export default {
		computed:{
			...mapState(['popList','userInfo','moneyInfo','config'])
		},
		name: 'Home',
		components: {
			HomeSanDianLiu,
			HomeConter,
			four,
		},
		data() {
			return {
				isConterType:2,
			}
		},
		created() {
			
		},
		mounted() {
		
		},
		beforeDestroy() {
		},
		methods: {
		
		}
	}
</script>

